var t1, t2, t3, slideIndex = 1,
    items = [],
    timeOut = null,
    timeOutNotify = null,
    searchResult = $('.search-result'),
    _ = function(e) {
        return document.querySelector(e);
    };
var cart_id;
var openModal = $("[class*='open-modal--']"),
    closeModal = $(".close-modal"),
    modal = "";
var toggleDiv = $('[data-toggle="true"]');
var loginRedirect =true;

(function($) {
    $.fn.visible = function(show, hide) {
        var win = $(window),
            w = winPos(win);
        return this.each(function(i, el) {
            var item = {
                el: el,
                show: show,
                hide: hide,
                shown: false
            };
            items.push(item);
            check(win, w, item);
        });
    };
    $.fn.lazyLoad = function() {
        return this.visible(function() {
            if ($(this).hasClass('lazy')) {
                $(this).removeClass('lazy');
                var $qs = $(this).attr('src').split('?');
                $(this).attr('src', $qs[0] + '?q=50&w=' + $(this).parents('div').width()).load(function() {
                    $(this).animate({
                        opacity: 1
                    });
                });
            }
        }, function() {
            $(this).removeClass('lazy');
        });
    };
})(jQuery);

function winPos(win) {
    return {
        x: 0,
        y: 0,
        x2: win.width() - 1,
        y2: win.height() - 1
    };
}

function pos(win, el) {
    var p = el.offset();
    var x = p.left - win.scrollLeft();
    var y = p.top - win.scrollTop();
    return {
        x: x,
        y: y,
        x2: x + el.width() - 1,
        y2: y + el.height() - 1
    };
}

function fun_set_value(id, value) {
    $("#" + id).val(value);
}

function fun_get_value(id) {
    return encodeURIComponent($.trim($("#" + id).val()));
}

function fun_get_value_we(id) {
    return $.trim($("#" + id).val());
}

function fun_apply_coupon() {
    var coupon = fun_get_value('txt_coupon');
    fun_show_loader();
    $.ajax({
        type: "POST",
        dataType: "JSON",
        url: baseURL + "ajax/checkout-process/APPLY-COUPON",
        data: "coupon=" + coupon + '&_token=' + fun_get_token(),
        success: function(response) {
            fun_hide_loader();
            if (response.status == true) {
                window.location = window.location.href;
            } else {
                fun_error_message(response.message);
            }
        },
        error: function() {
            fun_error_message("Error occured please try after some time");
        }
    });
}

function intersects(a, b) {
    return !(a.x2 < b.x || a.x > b.x2 || a.y2 < b.y || a.y > b.y2);
}

function check(win, w, item) {
    var p = pos(win, $(item.el));
    var s = intersects(w, p);
    if (s != item.shown) {
        item.shown = s;
        (s ? item.show : item.hide).call(item.el);
    }
}

function slide(n) {
    this.showSlide(slideIndex += n);
}

function showSlide(n) {
    clearTimeout(t1);
    clearTimeout(t2);
    clearTimeout(t3);
    var i;
    var x = document.getElementsByClassName("banner");
    if (n > x.length) {
        slideIndex = 1
    }
    if (n < 1) {
        slideIndex = x.length
    }
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    var tis = $(x[slideIndex - 1]);
    if (!tis.hasClass('loaded')) {
        $qs = tis.find('img').attr('src').split('?');
        tis.find('img').attr('src', $qs[0] + '?q=70&w=' + $(x).width());
        tis.addClass('loaded');
    }
    x[slideIndex - 1].style.display = "block";
    t1 = setTimeout(this.carousel, 3000);
}

function carousel() {
    var i;
    var x = document.getElementsByClassName("banner");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > x.length) {
        slideIndex = 1
    }
    var tis = $(x[slideIndex - 1]);
    if (!tis.hasClass('loaded') && tis.length) {
        $qs = tis.find('img').attr('src').split('?');
        tis.find('img').attr('src', $qs[0] + '?q=70&w=' + $(x).width());
        tis.addClass('loaded');
    }
    if (tis.length) {
        x[slideIndex - 1].style.display = "block";
        t3 = setTimeout(this.carousel, 3000);
    }
}

function is_touch_device() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

function hasClass(target, className) {
    return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
}

function visible(show, hide) {
    var win = $(window),
        w = winPos(win);
    return this.each(function(i, el) {
        var item = {
            el: el,
            show: show,
            hide: hide,
            shown: false
        };
        items.push(item);
        check(win, w, item);
    });
}

function start_search_by_voice() {
    var recognition = new(window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 10;
    recognition.start();
    recognition.onresult = function(event) {
        fetch_sesrch_result(event.results[0][0].transcript);
        _("input.search").value = event.results[0][0].transcript;
    };
}
// _("input.search").addEventListener("click", function() {
//     start_search_by_voice();
// });

function search_result(result) {
    if (!result.length) {
        searchResult.addClass('d-none');
        return;
    }
    var res = '';
    $.each(result, function(i, v) {
        res += '<p data-id="' + v.ci + '" data-name="' + v.sk + '" data-type="' + v.type + '" data-url="' + v.ci + '">' + v.uv + '</p>';
    });
    searchResult.html(res);
    searchResult.removeClass('d-none');
    searchResult.find('p').click(function() {
        var tis = $(this);
        if (tis.data('type') == 'product') {
            window.location = baseURL + 'product/' + tis.data('url');
        } else if (tis.data('type') == 'category') {
            var sk = tis.data('name');
            var cat_id = tis.data('id');
            $.get(baseURL + 'ajax/get-cat-seo-url', {
                id: tis.data('id')
            }, function(res) {}, 'json').done(function(res) {
                window.location = baseURL + 'search-result?key=' + sk;
            });
        }
    });
}

function fetch_sesrch_result(query) {
    if (query.trim() !== '') {
        clearTimeout(timeOut);
        timeOut = setTimeout(function() {
            $.get(baseURL + 'ajax/product-auto-complete', {
                q: query
            }, function() {}).done(function(result) {
                search_result(result);
            }).fail(function(e) {
                searchResult.addClass('d-none');
            });
        }, 500);
    } else {
        setTimeout(function() {
            searchResult.addClass('d-none');
        }, 600);
    }
}

function fun_get_token() {
    return $("input[name=_token]").val();
}

function toast(msg, sec, type) {
    var notify = $("#snackbar");
    notify.html(msg).addClass('show').addClass(type);
    clearTimeout(timeOutNotify);
    var timeOutNotify = setTimeout(function() {
        notify.removeClass('show').removeClass(type);
    }, sec);
}

function fun_open_modal(id) {
    $('.modal').hide();
    return $('#' + id).show();
}

function fun_close_modal(id) {
    return $('#' + id).hide();
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function form_validate(form) {
    form.find('.validate').each(function() {
        var val = $(this).val();
        var tis = $(this);
        if (tis.hasClass('required')) {
            if (val.trim().length === 0) {
                tis.addClass('red');
            } else {
                tis.removeClass('red');
            }
        }
        if (tis.hasClass('email')) {
            if (validateEmail(tis.val())) {
                tis.removeClass('red');
            } else {
                tis.addClass('red');
            }
        }
    });
    return true;
}

function fun_success_message(msg) {
    toast(msg, 5000, 'success');
}

function fun_error_message(msg) {
    toast(msg, 5000, 'error');
}

function fun_hide_loader() {}

function fun_show_loader() {}

function fun_remove_cart_item(id) {
    $.ajax({
        type: "POST",
        dataType: "JSON",
        url: baseURL + "product-process/REMOVE-FROM-CART",
        data: "id=" + id + '&_token=' + fun_get_token(),
        success: function(response) {
            if (response.status == true) {
                window.location = window.location.href;
            } else {
                fun_error_message(response.message);
            }
        },
        error: function() {
            fun_error_message("Error occured !!! Please try after sometime.");
        }
    });
}

function fun_add_to_cart(id,redirect,is_variant)
{   
    var variants = true;
    var alert = '';
    if(is_variant) {
        $('[name="variants[]"]').each(function(){
            if(!$('[name="variants'+$(this).val()+'"]').is(':checked')) { variants = false; alert += $('[name="variants'+$(this).val()+'"]').attr('data-alert') + ', ';}
        });
    }
    if(variants) {

        if($('[name="is_variant"]').val() == '1') {
        var variants = '&variants=' + $('#product_variants').serializeArray()
        .filter(function(form){ return form.name!='variants[]';})
                .filter(function(form){return form.name != 'variants_count';})
                .map(function(config){ return config.value;}).toString();
            } else {
                var variants = '';
            }

        $.ajax({
            url: baseURL + "check-login",
            type: 'GET',
            dataType: "JSON",
            data:"id="+id+'&_token='+fun_get_token() + variants,
            success: function(data) {
                if(data.status === true) {
                    add_to_cart(id,redirect,is_variant);
                    $('#login-modal').hide();
                    
                } else {
                    fun_error_message('Please login before add product to your cart.');
                    $('#login-modal').show();
                    loginRedirect = false;
                }
        }
         });
    } else {
        fun_error_message('Please select product ' + alert.trim().slice(0,-1));
    }
    
    
}





function fun_add_to_wish_list(id, type) {
    $.ajax({
        type: "POST",
        dataType: "JSON",
        url: baseURL + "product-process/ADD-TO-WISH-LIST",
        data: "id=" + id + '&_token=' + fun_get_token(),
        success: function(response) {
            if (response.status == true) {
                toast(response.message, 5000, 'success');;
                $(".wish_list_count").html(response.wish_list_count);
                $('.wishlist-' + id).toggleClass('in');
                if (type === 'remove-from-cart') {
                    fun_remove_cart_item(id);
                }
            } else {
                toast(response.message, 5000, 'error');
            }
        },
        error: function() {
            toast("Error occured !!! Please try after sometime.", 3000, 'error');
        }
    });
}

function fun_check_pincode_availability(type) {
    var pincode = $('input[name="pincode"]').val().trim();
    var result = $('.delivery-duration');
    if (pincode === '' || pincode.length < 6 || pincode.length > 6) {
        toast('Please enter valid pincode', 3000, 'error');
        return false;
    }
    $.post(baseURL + "ajax/pincode-process/CHECK", {
        pincode: pincode,
        _token: fun_get_token(),
        type: type
    }, function() {}, 'json').done(function(res) {
        if (res.status == true) {
            result.html(pincode + ' - Selected pincode having a delivery availability');
        } else {
            (type == 1) ? result.html("Oops! We dont deliver to this location yet. Delivery now available only in central Kerala"): result.html("Oops! We dont deliver to this location yet.");
        }
    }).fail(function(rse) {
        result.html("Oops! Error occured please try again.");
    });
}


function add_to_cart(id, redirect,is_variant) {
    if ($('button.btn-add-to-cart').hasClass('added')) {
        window.location = baseURL + 'my-cart';
    }

    var variants = true;
    var alert = '';
    if(is_variant) {
        $('[name="variants[]"]').each(function(){
            if(!$('[name="variants'+$(this).val()+'"]').is(':checked')) { variants = false; alert += $('[name="variants'+$(this).val()+'"]').attr('data-alert') + ', ';}
        });
    }
    if(variants) {

    var redirect = redirect;
    if($('[name="is_variant"]').val() == '1') {
        var variants = '&variants=' + $('#product_variants').serializeArray()
        .filter(function(form){ return form.name!='variants[]';})
        .filter(function(form){return form.name != 'variants_count';})
        .map(function(config){ return config.value;}).toString();
    } else {
        var variants = '';
    }
    $.ajax({
        type: "POST",
        dataType: "JSON",
        url: baseURL + "product-process/ADD-TO-CART",
        data: "id=" + id + '&_token=' + fun_get_token() + variants,
        success: function(response) {
            if (redirect === false && response.status === true) {
                $('button.btn-add-to-cart').addClass('added').html('GO TO CART');
                $('.cart-count').html(response.cart_items.length);
            }
            if (response.status == true) {
                fun_success_message(response.message);
                if (response.ga == true) {
                    ga('send', 'event', 'addtocart', 'click', 'product ' + response.product.product_name, response.product.offer_price);
                }
                if (response.fb) {
                    fbq('track', 'AddToCart');
                }
            } else {
                fun_error_message(response.message);
            }
            if (redirect === true && response.status == true) {
                window.location = baseURL + 'my-cart';
            }
        },
        error: function() {
            fun_error_message("Error occured !!! Please try after sometime.");
        }
    });
}
    else {
        fun_error_message('Please select product ' + alert.trim().slice(0,-1));
    }
}

function buy_now(id) {
    if($('[name="is_variant"]').val() == '1') {
        var variants = '&variants=' + $('#product_variants').serializeArray()
        .filter(function(form){ return form.name!='variants[]';})
        .filter(function(form){return form.name != 'variants_count';})
        .map(function(config){ return config.value;}).toString();
    } else {
        var variants = '';
    }
    $.ajax({
        type: "POST",
        dataType: "JSON",
        url: baseURL + "product-process/ADD-TO-CART",
        data: "id=" + id + '&_token=' + fun_get_token() + variants,
        success: function(response) {
            if (response.fb) fbq('track', 'AddToCart');
            if (response.ga) {
                ga('send', 'event', 'addtocart', 'click', 'product ' + response.product.product_name, response.product.offer_price);
            }
            fun_success_message("Product added to cart, please wait we're redirecting to cart page");
            setTimeout(function() {
                window.location = baseURL + 'my-cart';
            }, 1000);
        },
        error: function() {
            fun_error_message("Error occured !!! Please try after sometime.");
        }
    });
}

function fun_update_product_condition(id) {
    $('.item-price').html('<b>Select product and it`s condition to see price.</b>');
    $.ajax({
        url: baseURL + "get-exchange-item-spec",
        type: 'GET',
        dataType: "JSON",
        data: "id=" + $(id).val() + '&_token=' + fun_get_token(),
        success: function(data) {
            if (data.status === true) {
                var html = '<option value="">Product Condition</option>';
                $.each(data.spec, function(k, v) {
                    html += '<option data-price="' + v.price + '" value="' + v.spec_id + '">' + v.item_spec + '</option>';
                });
                $('[name="exchange_product_spec"]').html(html);
            }
        }
    });
}

function fun_update_product_price(e) {
    var price = $(e).find('[value="' + $(e).val() + '"]').attr('data-price');
    if (typeof price === "undefined") {
        $('.item-price').html('<b>Select product and it`s condition to see price.</b>');
    } else {
        $('.item-price').html('<h2>Rs. ' + price + '</h2>');
    }
}

function fun_buy_with_exchange(product_id) {
    var item = $('[name="exchange_product"]').val();
    var spec = $('[name="exchange_product_spec"]').val();
    if (item === '' || spec === '' || typeof item === "undefined" || typeof spec === "undefined") {
        fun_error_message('Please select product and it`s condition.');
    } else {
        $.ajax({
            url: baseURL + "buy_with_exchange",
            type: 'POST',
            dataType: "JSON",
            data: "id=" + product_id + '&_token=' + fun_get_token() + '&' + $('#exchangeProductForm').serialize(),
            success: function(response) {
                buy_now(product_id);
            },
            error: function() {
                fun_error_message("Error occured !!! Please try after sometime.");
            }
        });
    }
}

function update_cart_qty(product_id, qty) {
    $.post(baseURL + 'update_cart_qty', {
        'product_id': product_id,
        '_token': fun_get_token(),
        'qty': qty
    }, function() {}, 'json').done(function(res) {
        if (res.status === true) {
            fun_success_message("Quantity updated successfully");
            location.reload();
        } else {
            fun_error_message("Error occured !!! Please try again.");
        }
    }).fail(function() {
        fun_error_message("Error occured !!! Please try after sometime.");
    })
}
function progress() {
  var elem = document.getElementById("myBar");   
  var width = 1;
  var id = setInterval(frame, 10);
  function frame() {
    if (width >= 100) {
      clearInterval(id);
      $(document.getElementById("myProgress")).remove();
    } else {
      width++; 
      elem.style.width = width + '%'; 
    }
  }
}

function single_variants(){
     var single_variants = variants.filter(function(variant) {return variant.qty == 0;});
        $.each(single_variants , function(i,k){
            $('input[value="'+k.config_ids+'"]').attr('disabled',true);;
            $('label[for="'+k.config_ids+'"]').addClass('disabled');
        });
}

function more_than_variants() {

        var item_id = location.search.split('&').filter(function(val){return val.indexOf('lsvi') !=-1;});
        var variant_id = location.search.split('&').filter(function(val){return val.indexOf('lsvv') !=-1;});
        
        if(item_id.length > 0 && variant_id.length > 0 ) {

            item_id = item_id[0].split('=')[1];
            variant_id = variant_id[0].split('=')[1];
            // console.log(item_id);
            // console.log(variant_id);
             var available_products = variants.filter(function(variant){return (variant.config_ids).indexOf(item_id) != -1 && variant.qty > 0;});
        // console.log(available_products);
             $('input[type="radio"]').attr('disabled',true);
             $('label.label').addClass('disabled');

             $('input.variants'+variant_id).removeAttr('disabled');
             $('label.variants'+variant_id).removeClass('disabled');
             $.each(available_products ,function(i,v){

                    $.each(v.config_ids.split(','),function(j,k){
                     $('input[value="'+k+'"]').removeAttr('disabled');
                     $('label[for="'+k+'"]').removeClass('disabled');
                     
                    });
                
            });
        }
       
}


$(document).ready(function() {

    if($('[name="variants_count"]').val() == 1) {
        single_variants();
       
    } else {

        more_than_variants();
    }

$('.variants label').click(function(){

    if(!$(this).hasClass('disabled') && ! $('#'+$(this).attr('for')).is(':checked')) {
        $('body').append('<div id="myProgress"><div id="myBar"></div><div class="blur"></div></div>');
        progress();
        var lsVi = $(this).attr('for');
        var lsV = $(this).attr('data-variant');
        setTimeout(function() {
            var selectedVariants = $('#product_variants').serializeArray()
        .filter(function(form){ return form.name!='variants[]';})
        .filter(function(form){return form.name != 'variants_count';})
        .map(function(config){ return config.value;}).toString();
            
            // console.log(selectedVariants);
            var url = location.origin + location.pathname + '?variants=' + selectedVariants + '&lsvi=' + lsVi + '&lsvv=' + lsV;
            location.href = url;
            // console.log(url);
        },100);
        

        // if($('[name="variants_count"]').val() == 1) {
        //      single_variants();
        // } 
        // else {
        //         var item_id = $(this).attr('for');
        //         var variant_id = $(this).attr('data-variant');
               
        //         var available_products = variants.filter(variant => (variant.config_ids).indexOf($(this).attr('for')) != -1 && variant.qty > 0);
                
        //          $('input[type="radio"]').attr('disabled',true);
        //          $('label.label').addClass('disabled');

        //          $('input.variants'+variant_id).removeAttr('disabled');
        //          $('label.variants'+variant_id).removeClass('disabled');
        //         $.each(available_products ,function(i,v){

        //                 $.each(v.config_ids.split(','),function(j,k){
        //                  $('input[value="'+k+'"]').removeAttr('disabled');
        //                  $('label[for="'+k+'"]').removeClass('disabled');
                         
        //                 });
                    
        //         });
                
        // }
         
       
    }
   
});

    $('.minus,.plus').click(function() {
            var max= parseInt($(this).parents('.quantity').attr('data-max'));
            var isSubstract= parseInt($(this).parents('.quantity').attr('data-substract'));
            var qty = parseInt($(this).parents('.quantity').find('input.qty').val());
            var product_id = $(this).attr('data-id');
            if(max > 5 || isSubstract == 0) { max = 5 };
            if ($(this).hasClass('minus')) {
                if (qty > 1 && qty <= max) {
                    update_cart_qty(product_id, qty - 1);
                    $(this).parents('.quantity').find('input.qty').val(qty - 1);
                } else {
                    fun_error_message("Maximum allowed (or) available  quantity is " + max);
                }
            } else {
                if (qty >= 1 && qty < max) {
                    update_cart_qty(product_id, qty + 1);
                    $(this).parents('.quantity').find('input.qty').val(qty + 1);
                } else {
                    fun_error_message("Maximum allowed (or) available  quantity is " + max);
                }
            }
            }); 
      $('.qty').on('click', function(e) {   $(this).select(); });

    $('.qty').on('keypress', function(e) {
    var max= parseInt($(this).parents('.quantity').attr('data-max'));
    var qty = parseInt(String.fromCharCode(e.which));
     if(max > 5) { max = 5 };
    var product_id = $(this).attr('data-id');
    if (qty >= 1 && qty <= max) {
        update_cart_qty(product_id, qty);
    } else {
        fun_error_message("Maximum allowed (or) available  quantity is " + max);
        e.preventDefault();
        return false;
        
    }
}); 
    $('.search-form').submit(function(e) {
    e.preventDefault();
    var key = $('[name="search"]').val();
    if (key.length >= 3) {
        window.location = baseURL + 'search-result?key=' + $('[name="search"]').val();
    }
}); $('.submit-search').click(function() {
    var key = $('[name="search"]').val();
    if (key.length >= 3) {
        window.location = baseURL + 'search-result?key=' + $('[name="search"]').val();
    }
});
var modalAlert = $('.modal .alert'); 

$('#enquireProductForm').submit(function(e){
    $('.form-loader').removeClass('d-none');
    var Alert = $('#enquiryform-modal .alert');
    Alert.removeClass('in').removeClass('error').removeClass('success').html('');
    var form = $(this);

    if (form_validate(form) && form.find('.red').length === 0) {
        $.post(baseURL + 'product_enquiry', form.serialize(), function() {}, 'json').done(function(res) {
            if (res.status === true) {
            
               Alert.addClass('in').removeClass('error').addClass('success').html(res.msg);
            }  else {
               Alert.addClass('in').removeClass('success').addClass('error').html(res.msg);
            }
            $('.form-loader').addClass('d-none');


            $('#enquireProductForm')[0].reset();
            
        }).fail(function(res) {
            toast('Error occured please try again', 5000, 'error');
            $('.form-loader').addClass('d-none');
        });
        return false;
    } else {
        $('.form-loader').addClass('d-none');
        e.preventDefault();
        return false;
    }

});

$('#loginForm').submit(function(e) {
    $('.form-loader').removeClass('d-none');
    modalAlert.removeClass('in').removeClass('error').removeClass('success').html('');
    var form = $(this);
    if (form_validate(form) && form.find('.red').length === 0) {
        $.post(baseURL + 'login-process', form.serialize(), function() {}, 'json').done(function(res) {
            if (res.status === true) {
                toast(res.message, 5000, 'success');
                if(loginRedirect){
                    window.location = res.redirect;
                } else {
                    window.location = baseURL + 'my-cart';
                }
                
            } else {
                if(res.message === 'NOT_VERIFIED') {

                    $('#otpverifyForm').find('[name="id"]').val(res.customer_id);
                    $('.optverifyform').removeClass('d-none');
                    $('.loginforminner').addClass('d-none');
                    $('#loginForm')[0].reset();
                    modalAlert.addClass('in').addClass('error').html('Mobile number not verified.Please enter OTP.If not received click resend.');
                } else {
                    toast(res.message, 5000, 'error');
                    modalAlert.addClass('in').addClass('error').html(res.message);
                }
              
            }
            $('.form-loader').addClass('d-none');
        }).fail(function(res) {
            toast('Error occured please try again', 5000, 'error');
            $('.form-loader').addClass('d-none');
        });
        return false;
    } else {
        $('.form-loader').addClass('d-none');
        e.preventDefault();
        return false;
    }
}); $('#registrationForm').submit(function(e) {
    modalAlert.removeClass('in').removeClass('error').removeClass('success').html('');
    var form = $(this);
    $('.form-loader').removeClass('d-none');
    if (form_validate(form) && form.find('.red').length === 0) {
        $.post(baseURL + 'register-process', form.serialize(), function() {}, 'json').done(function(res) {
            $('.form-loader').addClass('d-none');
            if (res.process === 'STEP1') {
                var $errors = '<p style="margin:0">';
                $.each(res.error, function(i, v) {
                    $errors += v + '<br/>';
                });
                $errors += '</p>';
                modalAlert.addClass('in').removeClass('success').addClass('error').html($errors);
            } else if (res.process === 'STEP2') {
                $('#otpverifyForm').find('[name="id"]').val(res.customer_id);
                $('.optverifyform').removeClass('d-none');
                $('.registerforminner').addClass('d-none');
                $('#registrationForm')[0].reset();
                modalAlert.addClass('in').removeClass('error').addClass('success').html(res.msg);
            }
        }).fail(function(res) {
            toast('Error occured please try again', 5000, 'error');
            $('.form-loader').addClass('d-none');
            modalAlert.addClass('in').addClass('error').removeClass('success').html('Error occured please try again');
        });
        return false;
    } else {
        e.preventDefault();
        $('.form-loader').addClass('d-none');
        return false;
    }
}); $('#otpverifyForm').submit(function(e) {
    modalAlert.removeClass('in').removeClass('error').removeClass('success').html('');
    var form = $(this);
    $('.form-loader').removeClass('d-none');
    if (form_validate(form) && form.find('.red').length === 0) {
        $.post(baseURL + 'otp-verify', form.serialize(), function() {}, 'json').done(function(res) {
            if (res.status === true) {
                $('.optverifyform').addClass('d-none');
                $('.loginforminner').removeClass('d-none');
                $('#otpverifyForm')[0].reset();
                modalAlert.addClass('in').removeClass('error').addClass('success').html(res.msg);
            } else {
                modalAlert.addClass('in').addClass('error').removeClass('success').html(res.msg);
            }
            $('.form-loader').addClass('d-none');
        }).fail(function(res) {
            $('.form-loader').addClass('d-none');
            toast('Error occured please try again', 5000, 'error');
            modalAlert.addClass('in').addClass('error').removeClass('success').html('Error occured please try again');
        });
        return false;
    } else {
        $('.form-loader').addClass('d-none');
        e.preventDefault();
        return false;
    }
}); $('#resetPasswordForm').submit(function(e) {
    modalAlert.removeClass('in').removeClass('success').removeClass('error').html('');
    var form = $(this);
    $('.form-loader').removeClass('d-none');
    if (form_validate(form) && form.find('.red').length === 0) {
        $.post(baseURL + 'forgot-password-process', form.serialize(), function() {}, 'json').done(function(res) {
            if (res.status === true) {
                $('.forgotpasswordforminner').addClass('d-none');
                $('.forgotpasswordforminner-otp').removeClass('d-none');
                $('#resetPasswordForm')[0].reset();
                modalAlert.addClass('in').removeClass('error').addClass('success').html(res.msg);
                $('form').find('[name="enc_id"]').val(res.customer_id);
            } else {
                modalAlert.addClass('in').addClass('error').removeClass('success').html(res.msg);
            }
            $('.form-loader').addClass('d-none');
        }).fail(function(res) {
            $('.form-loader').addClass('d-none');
            toast('Error occured please try again', 5000, 'error');
            modalAlert.addClass('in').addClass('error').removeClass('success').html('Error occured please try again');
        });
        return false;
    } else {
        $('.form-loader').addClass('d-none');
        e.preventDefault();
        return false;
    }
}); $('#resetPassword-OTP').submit(function(e) {
    modalAlert.removeClass('in').removeClass('success').removeClass('error').html('');
    var form = $(this);
    $('.form-loader').removeClass('d-none');
    if (form_validate(form) && form.find('.red').length === 0) {
        $.post(baseURL + 'reset-password-process', form.serialize(), function() {}, 'json').done(function(res) {
            if (res.status === true) {
                $('.forgotpasswordforminner-otp').addClass('d-none');
                $('.loginforminner').removeClass('d-none');
                $('#resetPassword-OTP')[0].reset();
                modalAlert.addClass('in').removeClass('error').addClass('success').html(res.msg);
            } else {
                modalAlert.addClass('in').addClass('error').removeClass('success').html(res.msg);
            }
            $('.form-loader').addClass('d-none');
        }).fail(function(res) {
            $('.form-loader').addClass('d-none');
            toast('Error occured please try again', 5000, 'error');
            modalAlert.addClass('in').addClass('error').removeClass('success').html('Error occured please try again');
        });
        return false;
    } else {
        e.preventDefault();
        return false;
    }
}); $('.resend-otp').click(function() {
    $('.form-loader').removeClass('d-none');
    $.post(baseURL + 'resend-otp', {
        encrypted_id: $('.otpverifyForm').find('[name="id"]').val(),
        '_token': fun_get_token()
    }, function() {}, 'json').done(function(res) {
        $('.form-loader').addClass('d-none');
        if (res.status === true) {
            modalAlert.addClass('in').removeClass('error').addClass('success').html(res.msg);
        } else {
            modalAlert.addClass('in').addClass('errors').removeClass('success').html(res.msg);
        }
    }).fail(function(res) {
        $('.form-loader').addClass('d-none');
        modalAlert.addClass('in').addClass('errors').removeClass('success').html('Error occured please try again');
    });
}); $('.resend-otp-forget').click(function() {
    $('.form-loader').removeClass('d-none');
    $.post(baseURL + 'resend-otp', {
        encrypted_id: $('.otpverifyForm').find('[name="enc_id"]').val(),
        '_token': fun_get_token(),
        'type': 'forget'
    }, function() {}, 'json').done(function(res) {
        $('.form-loader').addClass('d-none');
        if (res.status === true) {
            modalAlert.addClass('in').removeClass('error').addClass('success').html(res.msg);
        } else {
            modalAlert.addClass('in').addClass('error').removeClass('success').html(res.msg);
        }
    }).fail(function(res) {
        $('.form-loader').addClass('d-none');
        modalAlert.addClass('in').addClass('error').removeClass('success').html('Error occured please try again');
    });
});
if (location.href.split('#')[1] === 'login-modal') {
    modal = $('#login-modal');
    modal.show();
}
openModal.click(function(e) {

    var variants = true;
    var alert = '';
    var is_variant = $('[name="is_variant"]').val()
    if(is_variant == '1') {
        $('[name="variants[]"]').each(function(){
            if(!$('[name="variants'+$(this).val()+'"]').is(':checked')) { variants = false; alert += $('[name="variants'+$(this).val()+'"]').attr('data-alert') + ', ';}
        });
    }
    if(!variants && $(this).attr('class').split('--')[1] === 'buywithexchange') {
       fun_error_message('Please select product ' + alert.trim().slice(0,-1));
        return true;
    }

    e.preventDefault();
    modalAlert.removeClass('in').removeClass('error').removeClass('success').html('');
    modal = $('#' + $(this).attr('class').split('--')[1] + '-modal');
    modal.show();
}); closeModal.click(function() {
    modal = $('#login-modal');
    if (location.href.split('#')[1] === 'login-modal') {
        window.history.pushState('', '', location.href.split('#')[0]);
    }
    modal.find('input[type="text"],input[type="password"],input[type="email"],input[type="number"]').each(function() {
        $(this).val('');
    });
    modal.hide();
}); $('.slide-btn-left').click(function() {
    slide(-1);
}); $('.slide-btn-right').click(function() {
    slide(1);
}); t2 = setTimeout(carousel, 3000); $('img.lazy').lazyLoad(); $('[href="' + $('.topCategoryUrl').attr('href') + '"]').addClass('active'); 

$('.prevent:not([href="products/hot-deals"])').click(function(e) {

    e.preventDefault();
}); 
$('input[name="search"]').keyup(function() {
    var query = $(this).val();
    fetch_sesrch_result(query);
});
if ($('.right-content').height() < ($(window).height() - 100)) {
    $('.left-content').css({
        minHeight: $('.right-content').height() - 15
    });
} else {
    $('.left-content').css({
        minHeight: ($(window).height() - 100)
    });
}
$('[name="filterId[]"]').click(function() {
    $('#filtersForm').submit();
});
if (typeof FILTERS != 'undefined') {
    var clearHtml = '';
    if (typeof FILTERS.filterId != 'undefined') {
        clearHtml += '<div class="clear-filter-sec">';
        $.each(FILTERS.filterId, function(i, v) {
            $('[value="' + v + '"]').prop('checked', true);
            clearHtml += '<span data-id="' + v + '">' + $('[value="' + v + '"]').attr('data-item-name') + '</span>';
        });
        clearHtml += '</div>';
    }
    if (clearHtml !== '') $('.clear-filter').html(clearHtml);
    if (typeof FILTERS.sortby != 'undefined') {
        $('.sort-list').removeClass('sort-active');
        $('a.' + FILTERS.sortby).parents('.sort-list').addClass('sort-active');
    }
    $('ul.pagination li a').each(function() {
        var href = $(this).attr('href');
        if (href) {
            href += (href.match(/\?/) ? '&' : '?') + QueryString.replace(/page=\d+/, '').replace(/&{1,}/g, '&');
            $(this).attr('href', href);
        }
    });
    $('.sort-div a').each(function() {
        var href = $(this).attr('href');
        if (href) {
            href += (href.match(/\?/) ? '&' : '?') + QueryString.replace(/sortby=[A-z]+/, '').replace(/&{1,}/g, '&');
            $(this).attr('href', href);
        }
    });
}
$('.max-6').keypress(function(e) {
    if ($(this).val().length > 5) {
        return false;
    }
    return true;
}); toggleDiv.click(function(e) {
    e.preventDefault();
    modalAlert.removeClass('in').removeClass('error').removeClass('success').html('');
    var data = $(this).data();
    $('.' + data.toggleOpen).removeClass('d-none');
    $('.' + data.toggleClose).addClass('d-none');
});
if (typeof WishList != 'undefined') {
    $.each(WishList, function(i, v) {
        $('.wishlist-' + v).addClass('in');
    });
}


// if($('[name="filterId[]"]:checked').length > 0) {
//     $.getJSON(baseURL + 'disable-filters',{'_token':fun_get_token(),'cat_id':$('[name="sub_category_id"]').val()},function(res){
   
// }).done(function(res) {
//     $('[name="filterId[]"]').attr('disabled','true').parents('div').css('color','#ccc');
//     $.each(res,function(i,v){
//         $('[value="'+ v.item_id+'"').removeAttr('disabled').parents('div').css('color','#333');
//     });

//     if($('[name="filterId[]"]:checked').length === 1){

//         $('[name="filterId[]"]:checked').parents('.iconlist').find('li [name="filterId[]"]').removeAttr('disabled').parents('div').css('color','#333');
//     }

//      $('[disabled="disabled"]').prop('checked', false);
// });
// }



});
$(window, 'body').on('scroll resize', function(e) {
    $('.lazy').lazyLoad();

    var pageUri = $('[ name="page-uri"]').val();
    var recentyViewed = $('.recently-viewed-products');

    if((pageUri === '/' || pageUri === 'product/{id}') && !recentyViewed.hasClass('loaded')) {

        recentyViewed.addClass('loaded');
        $.get(baseURL + 'recently-viewed',function(){

        },'html').done(function(response){

            if(response != 'NULL') {
                recentyViewed.html(response);
                recentyViewed.removeClass('hidden');
            }

        }).fail(function(){
            recentyViewed.addClass('hidden');
        });
    }
});
$(document).on('click', '.clear-filter span', function() {
    $('[value="' + $(this).attr('data-id') + '"]').prop('checked', false);
    $('#filtersForm').submit();
});
window.onclick = function(event) {
    if (event.target == modal) {
        modal.hide();
    }
}

$(window).resize(function(){
    
    if($(window).width() < 980) {
        location.reload();
    }
});